/* src/styles.css */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* body {
    font-family: var(--font-inter), sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-inter), sans-serif;
} */

html {
    scroll-behavior: smooth;
}

.black-background {
    background-color: black;
}

.each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 680px;
}

.each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}

.container-body {
    height: 9000px;
}

.bg-container {
    position: relative;
    /* width: 70% !important; */
    /* height: 600px; */
    margin: auto;
    /* margin-top: 55%; */
}

.bg-container h1 {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 150px; */
    font-size: 70px;
}

.homepage {
    position: relative;
}

.background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/bgImage.jpg');
    /* relative path from the public folder */
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.content {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.responsive-img {
    max-width: 100%;
    /* Ensure images don't exceed their container's width */
}


/* Adjust image sizes for different screen sizes */

@media screen and (max-width: 768px) {
    .responsive-img {
        width: 50%;
        /* Adjust image width for screens up to 768px */
    }
}

@media screen and (max-width: 480px) {
    .responsive-img {
        width: 80%;
        /* Adjust image width for screens up to 480px */
    }
}


/* Define custom percentage units */

:root {
    --pc: calc(100% / 100);
}

.left-18pc {
    left: calc(18 * var(--pc));
}

.right-26pc {
    right: calc(26 * var(--pc));
}